import React from "react";
import Layout from "../components/layout";
import CTA from "../components/cta";
import "./index.scss";

// images
import imgProjects from "../images/projects.svg";
import imgScale from "../images/scale.svg";
import imgPlatforms from "../images/platforms.svg";
import imgWalkieImg from "../images/walkieImage.png";
import imgWalkieImg2x from "../images/walkieImage@2x.png";
import imgWalkieImg3x from "../images/walkieImage@3x.png";
import imgStory from "../images/home_story_photo.jpg";
import imgPrep from "../images/home_prep_photo.jpg";
import imgShoot from "../images/home_shoot_photo.jpg";
import imgPost from "../images/home_post_photo.jpg";
import imgLaptop from "../images/main_laptop.png";
import imgKeyscreen from "../images/screenshot_shooting.png";
import imgIconCrew from "../images/crew_icon.svg";
import imgIconScreenwriting from "../images/screenwriting_icon.svg";
import imgIconBreakdown from "../images/script_tagging_icon.svg";
import imgIconStripboard from "../images/stripboard_icon.svg";
import imgIconStorage from "../images/deliverable_storage_icon.svg";
import imgIconShotlists from "../images/shotlists_icon.svg";
import imgIconCallsheets from "../images/smart_callsheets_icon.svg";
import imgIconWalkie from "../images/walkie_icon.svg";
import imgStripboardScreen from "../images/stripboard_screen.png";
import imgStripboardScreen2x from "../images/stripboard_screen@2x.png";
import imgStripboardScreen3x from "../images/stripboard_screen@3x.png";
import imgCallsheetsPage from "../images/call_sheet_page.png";
import imgCallsheetsPage2x from "../images/call_sheet_page@2x.png";
import imgCallsheetsPage3x from "../images/call_sheet_page@3x.png";
import imgCallsheetsMobile from "../images/callsheets_mobile.png";
import imgCallsheetsMobile2x from "../images/callsheets_mobile@2x.png";
import imgCallsheetsMobile3x from "../images/callsheets_mobile@3x.png";
import imgEditorScreen from "../images/editor_screen.png";
import imgEditorScreen2x from "../images/editor_screen@2x.png";
import imgEditorScreen3x from "../images/editor_screen@3x.png";
import imgTaggingScreen from "../images/tagging_screen.png";
import imgTaggingScreen2x from "../images/tagging_screen@2x.png";
import imgTaggingScreen3x from "../images/tagging_screen@3x.png";
import imgDirectoryScreen1 from "../images/directory_1.png";
import imgDirectoryScreen12x from "../images/directory_1@2x.png";
import imgDirectoryScreen13x from "../images/directory_1@3x.png";
import imgDirectoryScreen2 from "../images/directory_2.png";
import imgDirectoryScreen22x from "../images/directory_2@2x.png";
import imgDirectoryScreen23x from "../images/directory_2@3x.png";
import imgDirectoryScreen3 from "../images/directory_3.png";
import imgDirectoryScreen32x from "../images/directory_3@2x.png";
import imgDirectoryScreen33x from "../images/directory_3@3x.png";
import imgCastAndCrewIcon from "../images/cast_and_crew_icon.png";
import imgCastAndCrewIcon2x from "../images/cast_and_crew_icon@2x.png";
import imgCastAndCrewIcon3x from "../images/cast_and_crew_icon@3x.png";
import imgPropsIcon from "../images/props_icon.png";
import imgPropsIcon2x from "../images/props_icon@2x.png";
import imgPropsIcon3x from "../images/props_icon@3x.png";
import imgLocationsIcon from "../images/locations_icon.png";
import imgLocationsIcon2x from "../images/locations_icon@2x.png";
import imgLocationsIcon3x from "../images/locations_icon@3x.png";
import imgShotlistsShoots from "../images/shotlists_shots.png";
import imgShotlistsShoots2x from "../images/shotlists_shots@2x.png";
import imgShotlistsShoots3x from "../images/shotlists_shots@3x.png";
import imgShotlistsTakes from "../images/shotlists_takes.png";
import imgShotlistsTakes2x from "../images/shotlists_takes@2x.png";
import imgShotlistsTakes3x from "../images/shotlists_takes@3x.png";
import imgShotlistsSetups from "../images/shotlists_setups.png";
import imgShotlistsSetups2x from "../images/shotlists_setups@2x.png";
import imgShotlistsSetups3x from "../images/shotlists_setups@3x.png";
import imgShotlistsNotes from "../images/shotlists_notes.png";
import imgShotlistsNotes2x from "../images/shotlists_notes@2x.png";
import imgShotlistsNotes3x from "../images/shotlists_notes@3x.png";
import imgPushToTalkIcon from "../images/pushToTalk.svg";
import imgTalkToTextIcon from "../images/talkToText.svg";
import imgPrivateChatIcon from "../images/privateChat.svg";
import imgMobileWatchIcon from "../images/mobileWatch.svg";
import imgDesktopWebIcon from "../images/desktop.svg";
import imgWalkieConnectIcon from "../images/walkie.svg";
import imgShotlistsMobile from "../images/shotlists_group_mobile.png";
import imgPhoneImageMobile from "../images/phone_image_mobile.png";

const IndexPage = () => (
  <Layout>
    <div className="home">
      <section className="homeTop">
        <ul className="bgRows">
          <li className="red">
            <div
              className="image"
              style={{ backgroundImage: `url(${imgStory})` }}
              alt="Story. Write, edit, and collaborate on scripts from anywhere."
            />
            <div>
              <h2>story.</h2>
              <p>Write, edit, and collaborate on scripts from anywhere.</p>
            </div>
          </li>
          <li className="blue">
            <div
              className="image"
              style={{ backgroundImage: `url(${imgPrep})` }}
              alt="Prep. Updates automatically cascade to call sheets & planning."
            />
            <div>
              <h2>prep.</h2>
              <p>Updates automatically cascade to call sheets & planning.</p>
            </div>
          </li>
          <li className="yellow">
            <div
              className="image"
              style={{ backgroundImage: `url(${imgShoot})` }}
              alt="Shoot. Just-in-time Smart Callsheets that evolve through the day."
            />
            <div>
              <h2>shoot.</h2>
              <p>Just-in-time Smart Callsheets that evolve through the day.</p>
            </div>
          </li>
          <li className="green">
            <div
              className="image"
              style={{ backgroundImage: `url(${imgPost})` }}
              alt="Post. Real-time, on-shoot feedback at post-production fingertips."
            />
            <div>
              <h2>post.</h2>
              <p>
                Real-time, on-shoot feedback at post-production&apos;s
                fingertips.
              </p>
            </div>
          </li>
        </ul>

        <div className="laptop">
          <img src={imgLaptop} alt="ProCliq in Action" />
        </div>
        <div className="keyScreenshot">
          <img src={imgKeyscreen} alt="ProCliq Shooting Schedule" />
        </div>

        <div className="laptopText">
          <h2>
            Connecting your film together across all four stages of production.
          </h2>
          <button type="button" className="signUpButton prefinery-form-cta">
            Get the Early BETA
          </button>
        </div>
      </section>

      <div className="requestAccess">
        <button
          type="button"
          className="requestAccess mobile signUpButton prefinery-form-cta"
        >
          Get the Early BETA
        </button>
      </div>

      <section className="suiteOfFeatures">
        <div className="container">
          <div className="title">
            <h2>Suite of</h2>
            <h2>Features</h2>
          </div>

          <div className="columns">
            <ul>
              <li>
                <div className="icon">
                  <img src={imgIconCrew} />
                </div>
                Crew &amp; Element Directory
              </li>
              <li>
                <div className="icon">
                  <img src={imgIconScreenwriting} />
                </div>
                Collaborative Screenwriting
              </li>
              <li>
                <div className="icon">
                  <img src={imgIconBreakdown} />
                </div>
                Script Breakdown
              </li>
              <li>
                <div className="icon">
                  <img src={imgIconStripboard} />
                </div>
                Stripboard Schedules
              </li>
            </ul>
            <ul>
              <li>
                <div className="icon">
                  <img src={imgIconStorage} />
                </div>
                Deliverable Storage
              </li>
              <li>
                <div className="icon">
                  <img src={imgIconShotlists} />
                </div>
                Shotlists
              </li>
              <li>
                <div className="icon">
                  <img src={imgIconCallsheets} />
                </div>
                Smart Callsheets
              </li>
              <li>
                <div className="icon">
                  <img src={imgIconWalkie} />
                </div>
                Walkie
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="feature intuitiveScheduling">
        <img
          className="featureImage"
          src={imgStripboardScreen}
          srcSet={`${imgStripboardScreen} 1x, ${imgStripboardScreen2x} 2x, ${imgStripboardScreen3x} 3x,`}
          alt=""
        />
        <div className="content">
          <div className="top red">
            <h2>
              Intuitive
              <br />
              Scheduling
            </h2>
          </div>
          <div className="bottom">
            <p>
              Collaborate on schedules using customized category columns and
              hourly estimates across all shooting units.
            </p>
          </div>
          {/*
              <Link className="writing" to="/features/">
                More Features
              </Link>
              */}
        </div>
      </section>

      <section className="feature smartCallsheets left">
        <div className="featureImagesContainer">
          <img
            className="featureImage phone"
            src={imgCallsheetsMobile}
            srcSet={`${imgCallsheetsMobile} 1x, ${imgCallsheetsMobile2x} 2x, ${imgCallsheetsMobile3x} 3x,`}
            alt=""
          />
          <img
            className="featureImage page"
            src={imgCallsheetsPage}
            srcSet={`${imgCallsheetsPage} 1x, ${imgCallsheetsPage2x} 2x, ${imgCallsheetsPage3x} 3x,`}
            alt=""
          />
        </div>
        <div className="content">
          <div className="top blue">
            <h2>
              Smart
              <br />
              Call Sheets
            </h2>
          </div>
          <div className="bottom">
            <p>
              Easily adapt to daily changing needs with automatically generated
              call sheets for print and mobile devices, including mobile
              notifications and confirmations.
            </p>
          </div>
          {/*
                <Link className="writing" to="/features/">
                  More Features
                </Link>
                */}
        </div>
      </section>

      <section className="feature realtimeScreenwriting">
        <img
          className="featureImage"
          src={imgEditorScreen}
          srcSet={`${imgEditorScreen} 1x, ${imgEditorScreen2x} 2x, ${imgEditorScreen3x} 3x,`}
          alt=""
        />
        <div className="content">
          <div className="top yellow">
            <h2>
              Realtime
              <br />
              Collaborative
              <br />
              Screenwriting
            </h2>
          </div>
          <div className="bottom">
            <p>
              Write, edit, comment and publish script updates with ease all
              through production, with changes tracked for breakdowns.
            </p>
          </div>
          {/*
              <Link className="writing" to="/features/">
                More Features
              </Link>
              */}
        </div>
      </section>

      <section className="feature left integratedScriptTagging">
        <img
          className="featureImage"
          src={imgTaggingScreen}
          srcSet={`${imgTaggingScreen} 1x, ${imgTaggingScreen2x} 2x, ${imgTaggingScreen3x} 3x,`}
          alt=""
        />
        <div className="content">
          <div className="top green">
            <h2>
              Integrated
              <br />
              Script Tagging
            </h2>
          </div>
          <div className="bottom">
            <p>
              Highlight, tag, and categorize cast, crew, and key elements within
              a script. Accessible anywhere.
            </p>
          </div>
          {/*
              <Link className="writing" to="/features/">
                More Features
              </Link>
              */}
        </div>
      </section>

      <section className="directory">
        <div className="imagesContainer">
          <div className="image">
            <img
              className="directoryImage"
              src={imgDirectoryScreen1}
              srcSet={`${imgDirectoryScreen1} 1x, ${imgDirectoryScreen12x} 2x, ${imgDirectoryScreen13x} 3x,`}
              alt=""
            />
          </div>
          <div className="image">
            <img
              className="directoryImage"
              src={imgDirectoryScreen2}
              srcSet={`${imgDirectoryScreen2} 1x, ${imgDirectoryScreen22x} 2x, ${imgDirectoryScreen23x} 3x,`}
              alt=""
            />
          </div>

          <div className="image">
            <img
              className="directoryImage"
              src={imgDirectoryScreen3}
              srcSet={`${imgDirectoryScreen3} 1x, ${imgDirectoryScreen32x} 2x, ${imgDirectoryScreen33x} 3x,`}
              alt=""
            />
          </div>
        </div>

        <div className="content">
          <h2>
            Comprehensive
            <br />
            Directory
          </h2>
          <div className="items">
            <div className="item">
              <div className="icon">
                <img
                  src={imgCastAndCrewIcon}
                  srcSet={`${imgCastAndCrewIcon} 1x, ${imgCastAndCrewIcon2x} 2x, ${imgCastAndCrewIcon3x} 3x,`}
                  alt=""
                />
              </div>
              <h3>CAST &amp; CREW</h3>
              <p>Keep your cast &amp; crew lists organized with departments.</p>
            </div>
            <div className="item">
              <div className="icon">
                <img
                  src={imgPropsIcon}
                  srcSet={`${imgPropsIcon} 1x, ${imgPropsIcon2x} 2x, ${imgPropsIcon3x} 3x,`}
                  alt=""
                />
              </div>
              <h3>Props, Wardrobe, Vehicles, Etc.</h3>
              <p>
                Notate suppliers and track props, costumes, etc. all in one
                place.
              </p>
            </div>
            <div className="item">
              <div className="icon">
                <img
                  src={imgLocationsIcon}
                  srcSet={`${imgLocationsIcon} 1x, ${imgLocationsIcon2x} 2x, ${imgLocationsIcon3x} 3x,`}
                  alt=""
                />
              </div>
              <h3>Locations</h3>
              <p>
                Track status and contacts for your production locations with
                ease.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="shotlists">
        <div className="shotlistsMobile">
          <img src={imgShotlistsMobile} alt="ProCliq Shotlists" />
        </div>
        <div className="top">
          <div className="item screenshot">
            <img
              className="stootlistsImage"
              src={imgShotlistsShoots}
              srcSet={`${imgShotlistsShoots} 1x, ${imgShotlistsShoots2x} 2x, ${imgShotlistsShoots3x} 3x,`}
              alt=""
            />
          </div>
          <div className="item">
            <h2>
              Intuitive
              <br />
              Shotlists
              <br />
              and Takes
            </h2>
            <p>
              Plan shots and setups in advance or as you go, then capture
              information immediately for seamless handover to your post
              production team.
            </p>
          </div>
          <div className="item screenshot">
            <img
              className="stootlistsImage"
              src={imgShotlistsTakes}
              srcSet={`${imgShotlistsTakes} 1x, ${imgShotlistsTakes2x} 2x, ${imgShotlistsTakes3x} 3x,`}
              alt=""
            />
          </div>
          <div className="item screenshot">
            <img
              className="stootlistsImage"
              src={imgShotlistsSetups}
              srcSet={`${imgShotlistsSetups} 1x, ${imgShotlistsSetups2x} 2x, ${imgShotlistsSetups3x} 3x,`}
              alt=""
            />
          </div>
          <div className="item screenshot">
            <img
              className="stootlistsImage"
              src={imgShotlistsNotes}
              srcSet={`${imgShotlistsNotes} 1x, ${imgShotlistsNotes2x} 2x, ${imgShotlistsNotes3x} 3x,`}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="walkie">
        <h2>Walkie</h2>
        <h2>Reimagined</h2>
        <div className="content">
          <div className="deskColumn">
            <div className="iconColumn left">
              <div className="icon">
                <img
                  src={imgPushToTalkIcon}
                  srcSet={imgPushToTalkIcon}
                  alt=""
                />
                <div className="info">
                  <h3>PUSH TO TALK</h3>
                  <p>Push to talk streaming broadcast channels</p>
                </div>
              </div>
              <div className="icon">
                <img
                  src={imgTalkToTextIcon}
                  srcSet={imgTalkToTextIcon}
                  alt=""
                />
                <div className="info">
                  <h3>TALK TO TEXT</h3>
                  <p>Verbal discussions to text for quick review</p>
                </div>
              </div>
              <div className="icon">
                <img
                  src={imgPrivateChatIcon}
                  srcSet={imgPrivateChatIcon}
                  alt=""
                />
                <div className="info">
                  <h3>PRIVATE CHAT</h3>
                  <p>Private Channels and One-to-One Discussions</p>
                </div>
              </div>
            </div>
          </div>
          <div className="phoneContainer">
            <img
              src={imgWalkieImg}
              className="deskPhone"
              srcSet={`${imgWalkieImg} 1x, ${imgWalkieImg2x} 2x, ${imgWalkieImg3x} 3x`}
              alt=""
            />
            <img
              src={imgPhoneImageMobile}
              className="mobilePhone"
              alt="ProCliq Walkie"
            />
          </div>
          <div className="deskColumn">
            <div className="iconColumn">
              <div className="icon">
                <img
                  src={imgMobileWatchIcon}
                  srcSet={imgMobileWatchIcon}
                  alt=""
                />
                <div className="info">
                  <h3>Mobile & Watch</h3>
                  <p>Apps for Mobile phones and watch for easy access</p>
                </div>
              </div>
              <div className="icon">
                <img
                  src={imgDesktopWebIcon}
                  srcSet={imgDesktopWebIcon}
                  alt=""
                />
                <div className="info">
                  <h3>Desktop & Web</h3>
                  <p>Seemless integration in web & desktop environments</p>
                </div>
              </div>
              <div className="icon">
                <img
                  src={imgWalkieConnectIcon}
                  srcSet={imgWalkieConnectIcon}
                  alt=""
                />
                <div className="info">
                  <h3>Walkie Connect</h3>
                  <p>Connect physical walkies to ProCliq channels</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobileList">
          <div className="mobileColumn">
            <div className="icon">
              <img
                src={imgMobileWatchIcon}
                srcSet={imgMobileWatchIcon}
                alt=""
              />
              <div className="info">
                <h3>Mobile & Watch</h3>
                <p>Apps for Mobile phones and watch for easy access</p>
              </div>
            </div>
            <div className="icon">
              <img src={imgDesktopWebIcon} srcSet={imgDesktopWebIcon} alt="" />
              <div className="info">
                <h3>Desktop & Web</h3>
                <p>Seemless integration in web & desktop environments</p>
              </div>
            </div>
            <div className="icon">
              <img
                src={imgWalkieConnectIcon}
                srcSet={imgWalkieConnectIcon}
                alt=""
              />
              <div className="info">
                <h3>Walkie Connect</h3>
                <p>Connect physical walkies to ProCliq channels</p>
              </div>
            </div>
            <div className="icon">
              <img src={imgPushToTalkIcon} srcSet={imgPushToTalkIcon} alt="" />
              <div className="info">
                <h3>PUSH TO TALK</h3>
                <p>Push to talk streaming broadcast channels</p>
              </div>
            </div>
            <div className="icon">
              <img src={imgTalkToTextIcon} srcSet={imgTalkToTextIcon} alt="" />
              <div className="info">
                <h3>TALK TO TEXT</h3>
                <p>Verbal discussions to text for quick review</p>
              </div>
            </div>
            <div className="icon">
              <img
                src={imgPrivateChatIcon}
                srcSet={imgPrivateChatIcon}
                alt=""
              />
              <div className="info">
                <h3>PRIVATE CHAT</h3>
                <p>Private Channels and One-to-One Discussions</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="solutions">
        <div className="container">
          <div className="solution">
            <img src={imgProjects} alt="Projects" />
            <h3>All of your projects, past &amp; present</h3>
            <p>
              ProCliq stores everything in the cloud, giving you immediate
              access on all of your connceted devices. Changes appear instantly.
            </p>
          </div>
          <div className="solution">
            <img src={imgScale} alt="Scale" />
            <h3>Built for Scale</h3>
            <p>
              Designed to meet the needs of any size crew. Whether small-shoots
              or multi-unit productions, all work seamlessly & efficiently.
            </p>
          </div>
          <div className="solution">
            <img src={imgPlatforms} alt="Platforms" />
            <h3>Platform Agnostic &amp; Device Ambiguous</h3>
            <p>
              Optimized for everything from phone to desktop, ProCliq lets you
              take production communications with you.
            </p>
          </div>
        </div>
      </section>

      <section className="connected">
        <div className="container">
          <div className="content">
            <h1>filmmaking.</h1>
            <h1 className="blue">connected.</h1>
            <button type="button" className="prefinery-form-cta">
              Request BETA Access
            </button>
          </div>
        </div>
      </section>
    </div>
    <CTA />
  </Layout>
);

export default IndexPage;
